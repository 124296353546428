var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-10"},[_c('a-modal',{attrs:{"title":"Colors"},on:{"ok":_vm.handleColorChange},model:{value:(_vm.colourModal.visible),callback:function ($$v) {_vm.$set(_vm.colourModal, "visible", $$v)},expression:"colourModal.visible"}},[_c('div',{staticClass:"dF aC",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.colourList),function(color,colorI){return _c('div',{key:color + colorI,staticClass:"px-1 py-1",attrs:{"value":color}},[_c('div',{staticClass:"relative",staticStyle:{"border-radius":"50%","height":"20px","width":"20px","cursor":"pointer"},style:({ 'background-color': color }),on:{"click":function($event){_vm.selectedColor = color}}},[(_vm.selectedColor === color)?_c('div',{staticClass:"absolute",staticStyle:{"width":"100%","height":"100%","display":"flex","align-items":"center","justify-content":"center"}},[_c('i',{staticClass:"fe fe-check"})]):_vm._e()])])}),0)]),(!_vm.instances.length)?_c('div',{staticClass:"align-center mt-5"},[_c('img',{staticStyle:{"width":"100%","max-width":"500px"},attrs:{"src":require("@/assets/noinstance_found.svg"),"alt":"No instances found"}}),_c('h3',{staticClass:"mt-5"},[_vm._v("No Project Found")])]):_vm._e(),(_vm.instances.length && _vm.view === 'list')?_c('div',[_c('a-table',{staticClass:"list-view",attrs:{"columns":_vm.columns,"data-source":_vm.instances,"rowKey":(record) => record.key,"pagination":false,"expandedRowKeys":_vm.currentExpandedRowKeys,"expandIconAsCell":false,"expandIconColumnIndex":-1,"scroll":{x: 1100}},scopedSlots:_vm._u([{key:"action",fn:function(instance){return _c('div',{},[_c('div',{staticClass:"instance-row"},[_c('div',{staticClass:"icon-option"},[_c('div',{staticClass:"hide-hexagon hexagon hexagon2"},[_c('div',{staticClass:"hexagon-in1"},[_c('div',{staticClass:"hexagon-in2",style:({
                                            'background-color':
                                                _vm.getBackground(instance),
                                        })})])]),_c('a-popover',{attrs:{"trigger":"click","placement":"bottomLeft","overlayClassName":"remove-padding"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.colourInstance(instance)}}},[_vm._v(" Color ")]),_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.settingInstance(instance.id)}}},[_vm._v(" Settings ")]),(instance.own)?_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.billingInstance(instance.id)}}},[_vm._v(" Billing ")]):_vm._e(),(instance.own)?_c('div',{staticClass:"popover-option",on:{"click":function($event){_vm.selectedInstance = instance;
											_vm.deleteAccepted = false;
											_vm.downloadData = false;
                                            _vm.deleteModal = true;}}},[_vm._v(" Delete ")]):_vm._e()]),_c('div',{staticClass:"option-button",attrs:{"tabindex":"0"}},[_c('em',{staticClass:"fe fe-more-vertical",staticStyle:{"font-size":"25px","cursor":"pointer"},style:({
                                            color: _vm.getBackground(instance),
                                        })})])])],1)])])}},{key:"name",fn:function(instance){return _c('div',{staticClass:"dF"},[_c('div',[_c('span',[_vm._v(_vm._s(instance.name))]),_c('br'),(instance.allChild && instance.allChild.length)?_c('span',{staticStyle:{"color":"var(--med-gray)"}},[_vm._v(" "+_vm._s(instance.allChild.length)+" projects linked "),_c('span',{on:{"click":function($event){return _vm.handleRowExpand(instance.key)}}},[(
                                        _vm.currentExpandedRowKeys.indexOf(
                                            instance.key
                                        )
                                    )?_c('a-icon',{attrs:{"type":"down"}}):_vm._e(),(
                                        !_vm.currentExpandedRowKeys.indexOf(
                                            instance.key
                                        )
                                    )?_c('a-icon',{attrs:{"type":"up"}}):_vm._e()],1)]):_vm._e()])])}},{key:"expandedRowRender",fn:function(instance){return _c('div',{staticClass:"dF aC",staticStyle:{"margin":"0","gap":"10px"}},[_c('span',[_vm._v(" Linked Projects: ")]),_vm._l((instance.allChild),function(child){return _c('span',{key:child.id,staticClass:"child"},[_vm._v(" "+_vm._s(child.name)+" ")])})],2)}},{key:"readableId",fn:function(instance){return _c('div',{},[_vm._v(" "+_vm._s(instance.readableId || '')+" ")])}},{key:"productType",fn:function(instance){return _c('div',{},[_vm._v(" "+_vm._s(instance.productType === "lowrise" ? "Low Rise" : "High Rise")+" ")])}},{key:"createdAt",fn:function(instance){return _c('div',{staticStyle:{"color":"var(--med-gray)"}},[_vm._v(" "+_vm._s(_vm.convertDate(instance.createdAt))+" ")])}},{key:"lastVisitedAt",fn:function(instance){return _c('div',{staticStyle:{"color":"var(--med-gray)"}},[_vm._v(" "+_vm._s(_vm.lastVisited[instance.id] ? _vm.convertDate(_vm.lastVisited[instance.id]) : "")+" ")])}},{key:"owned/shared",fn:function(instance){return _c('div',{},[_c('a-row',{staticClass:"dF aC",attrs:{"gutter":25}},[_c('a-col',{attrs:{"span":3}}),_c('a-col',{attrs:{"span":6}},[_c('div',{staticStyle:{"font-size":"16px"}},[_c('a-tooltip',{attrs:{"slot":"prefix","title":instance.own
                                            ? 'Owned'
                                            : 'Shared with you',"overlayClassName":"change-tooltip-color"},slot:"prefix"},[(instance.own)?_c('em',{staticClass:"fe fe-user",staticStyle:{"color":"#d0c9d6"}}):_c('em',{staticClass:"fe fe-users",staticStyle:{"color":"#d0c9d6"}})])],1)]),_c('a-col',{attrs:{"span":6}},[_c('div',{staticStyle:{"font-size":"16px"}},[(_vm.linkedInstances.includes(instance.id))?_c('a-tooltip',{attrs:{"slot":"prefix","title":"Linked","overlayClassName":"change-tooltip-color"},slot:"prefix"},[_c('em',{staticClass:"fe fe-link",staticStyle:{"color":"#d0c9d6"}})]):_vm._e()],1)])],1)],1)}},{key:"parent",fn:function(instance){return _c('div',{},[_c('div',[(
                                instance.allChild &&
                                instance.allChild.length > 0
                            )?_c('a-tooltip',{attrs:{"slot":"prefix","title":"Parent Project","overlayClassName":"change-tooltip-color"},slot:"prefix"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","viewBox":"0 0 20.4 16.32","fill":"#FF9027"}},[_c('path',{staticClass:"cls-1",attrs:{"id":"Icon_awesome-crown","data-name":"Icon awesome-crown","d":"M16.83,14.28H3.57a.511.511,0,0,0-.51.51v1.02a.511.511,0,0,0,.51.51H16.83a.511.511,0,0,0,.51-.51V14.79A.511.511,0,0,0,16.83,14.28Zm2.04-10.2a1.53,1.53,0,0,0-1.53,1.53,1.5,1.5,0,0,0,.14.631L15.172,7.624a1.019,1.019,0,0,1-1.409-.37l-2.6-4.545a1.53,1.53,0,1,0-1.932,0l-2.6,4.545a1.019,1.019,0,0,1-1.409.37l-2.3-1.383a1.529,1.529,0,1,0-1.393.9,1.562,1.562,0,0,0,.245-.025l2.3,6.145H16.32l2.3-6.145a1.562,1.562,0,0,0,.245.025,1.53,1.53,0,1,0,0-3.06Z"}})])]):_vm._e()],1)])}}],null,false,23195823)})],1):_vm._e(),(_vm.instances.length && _vm.view === 'grid')?_c('div',[_c('div',{staticClass:"instance-grid dF fC aC mt-2",staticStyle:{"text-align":"center"}},_vm._l((_vm.instances),function(instance,index){return _c('a-card',{key:index},[_c('div',{staticClass:"instance-row dF fC aC mt-4",attrs:{"slot":"cover"},slot:"cover"},[_c('div',{staticClass:"icon-option",class:_vm.$mq != 'sm' ? 'mx-4' : 'mr-2'},[_c('div',{staticClass:"hexagon hexagon2",staticStyle:{"cursor":"default"}},[_c('div',{staticClass:"hexagon-in1"},[_c('div',{staticClass:"hexagon-in2",style:({
                                            'background-color':
                                                _vm.getBackground(instance),
                                        })})])])])]),_c('template',{staticClass:"ant-card-actions",slot:"actions"},[_c('a-row',{staticClass:"dF aC ml-2"},[_c('a-col',{attrs:{"span":2}},[_c('a-icon',{staticStyle:{"color":"#d0c9d6"},attrs:{"type":"clock-circle"}})],1),_c('a-col',{attrs:{"span":11}},[_c('span',{staticClass:"text-black"},[_vm._v(_vm._s(_vm.convertDate(instance.createdAt)))])]),_c('a-col',{attrs:{"span":2}},[_c('div',{staticStyle:{"font-size":"16px"}},[_c('a-tooltip',{attrs:{"slot":"prefix","title":instance.own
                                                ? 'Owned'
                                                : 'Shared with you',"overlayClassName":"change-tooltip-color"},slot:"prefix"},[(instance.own)?_c('em',{staticClass:"fe fe-user",staticStyle:{"color":"#d0c9d6"}}):_c('em',{staticClass:"fe fe-users",staticStyle:{"color":"#d0c9d6"}})])],1)]),_c('a-col',{attrs:{"span":3}},[_c('div',{staticStyle:{"font-size":"16px"}},[(
                                            _vm.linkedInstances.includes(
                                                instance.id
                                            )
                                        )?_c('a-tooltip',{attrs:{"slot":"prefix","title":"Linked","overlayClassName":"change-tooltip-color"},slot:"prefix"},[_c('em',{staticClass:"fe fe-link",staticStyle:{"color":"#d0c9d6"}})]):_vm._e()],1)]),_c('a-col',{attrs:{"span":3}},[_c('div',[(
                                            instance.allChild &&
                                            instance.allChild.length > 0
                                        )?_c('a-tooltip',{attrs:{"slot":"prefix","title":"Parent Project","overlayClassName":"change-tooltip-color"},slot:"prefix"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","viewBox":"0 0 20.4 16.32","fill":"#FF9027"}},[_c('path',{staticClass:"cls-1",attrs:{"id":"Icon_awesome-crown","data-name":"Icon awesome-crown","d":"M16.83,14.28H3.57a.511.511,0,0,0-.51.51v1.02a.511.511,0,0,0,.51.51H16.83a.511.511,0,0,0,.51-.51V14.79A.511.511,0,0,0,16.83,14.28Zm2.04-10.2a1.53,1.53,0,0,0-1.53,1.53,1.5,1.5,0,0,0,.14.631L15.172,7.624a1.019,1.019,0,0,1-1.409-.37l-2.6-4.545a1.53,1.53,0,1,0-1.932,0l-2.6,4.545a1.019,1.019,0,0,1-1.409.37l-2.3-1.383a1.529,1.529,0,1,0-1.393.9,1.562,1.562,0,0,0,.245-.025l2.3,6.145H16.32l2.3-6.145a1.562,1.562,0,0,0,.245.025,1.53,1.53,0,1,0,0-3.06Z"}})])]):_vm._e()],1)]),_c('a-col',{attrs:{"span":2}},[_c('a-popover',{attrs:{"trigger":"click","placement":"bottomLeft","overlayClassName":"remove-padding"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.colourInstance(instance)}}},[_vm._v(" Color ")]),_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.settingInstance(instance.id)}}},[_vm._v(" Settings ")]),(instance.own)?_c('div',{staticClass:"popover-option",on:{"click":function($event){return _vm.billingInstance(instance.id)}}},[_vm._v(" Billing ")]):_vm._e(),(instance.own)?_c('div',{staticClass:"popover-option",on:{"click":function($event){_vm.selectedInstance = instance;
                                                _vm.deleteModal = true;}}},[_vm._v(" Delete ")]):_vm._e()]),_c('div',{staticClass:"option-button",attrs:{"tabindex":"0"}},[_c('em',{staticClass:"fe fe-more-vertical",staticStyle:{"font-size":"25px","cursor":"pointer"}})])])],1)],1)],1),_c('div',{staticStyle:{"min-height":"22px"}},[_vm._v(" "+_vm._s(instance.readableId || '')+" ")]),_c('a-card-meta',{staticStyle:{"min-height":"24px"},attrs:{"title":instance.name}}),_c('div',{staticStyle:{"display":"inline-block","color":"var(--med-gray)"}},[_vm._v(" "+_vm._s(instance.allChild && instance.allChild.length ? `${instance.allChild.length} projects linked` : "")+" ")]),_c('div',{staticClass:"mt-4 text-black"},[_vm._v(" "+_vm._s(instance.productType === "lowrise" ? "Low Rise" : "High Rise")+" ")])],2)}),1)]):_vm._e(),(_vm.selectedInstance)?_c('a-modal',{attrs:{"title":"Delete Your Project","ok-text":"DELETE","ok-button-props":{ props: { disabled: !_vm.deleteAccepted } }},on:{"ok":function($event){return _vm.onDelete(_vm.selectedInstance.id)},"cancel":function($event){_vm.selectedInstance = null;
                _vm.deleteModal = false;}},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('p',[_vm._v(" You are about to delete your project "),_c('strong',[_vm._v(" "+_vm._s(_vm.selectedInstance.name)+" ")]),_vm._v(". All your data will be deleted. ")]),_c('p',[_c('b',[_vm._v("Consider downloading your data before deleting a project.")])]),_c('a-checkbox',{model:{value:(_vm.downloadData),callback:function ($$v) {_vm.downloadData=$$v},expression:"downloadData"}},[_c('span',[_vm._v(" DOWNLOAD DATA (Your data will consist of all your leads and all your completed transactions.) "),_c('a-tooltip',{attrs:{"overlayClassName":"change-tooltip-color"}},[_c('template',{slot:"title"},[_vm._v(" Upon selecting the checkbox an email with a link will be send to you that will allow you to download your data. ")]),_c('a-icon',{staticStyle:{"font-size":"12px"},attrs:{"type":"question-circle"}})],2)],1)]),_c('br'),_c('br'),_c('a-checkbox',{model:{value:(_vm.deleteAccepted),callback:function ($$v) {_vm.deleteAccepted=$$v},expression:"deleteAccepted"}},[_vm._v(" I understand that deleting my project can’t be undone and all my data will be deleted. ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }